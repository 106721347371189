import * as React from 'react'
import ReactMarkdown from 'react-markdown';
import DefaultLayout from "../layouts/default";
import {graphql, Page} from "gatsby";
import rehypeRaw from 'rehype-raw'
import {getDefaultTitle} from "../logic/seo";
import {Helmet} from "react-helmet";

export default class ImpressumPage extends React.Component<Page, {props}> {
    public render() {http://localhost:1337/admin/plugins/content-manager/singleType/application::datenschutz.datenschutz
        return (
            <div>
                <Helmet>
                    <title>{getDefaultTitle("Impressum")}</title>
                    <meta name="description" content={this.props.data.strapiStaticPages.metaDescription}/>

                </Helmet>
                <DefaultLayout>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} >{this.props.data.strapiStaticPages.text}</ReactMarkdown>
                </DefaultLayout>
            </div>

        )
    }
}

export const query = graphql`
query impressum {
  strapiStaticPages(identifier: {eq: "Impressum"}) {
    id
    text
  }
}
`